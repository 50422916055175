// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Contract_container__nErhy,
.Contract_loadingRequest__Wio03 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 3em;

}

.Contract_container__nErhy .Contract_subContainer__d3nhu {
    max-width: 1300px;
    width: 100%;
    padding: 1em;
    display: flex;
    flex-direction: column;
    min-height: 50vh;
    margin-top: 5em;
}

.Contract_container__nErhy .Contract_subContainer__d3nhu .Contract_return__GJ4Q6 {
    width: 15%;
}

.Contract_container__nErhy .Contract_subContainer__d3nhu a {
    display: flex;
    align-items: center;
    text-decoration: none;
    gap: 0.5em;
    font-size: 14px;
    font-weight: 400;
    color: #727588;
}

.Contract_container__nErhy .Contract_subContainer__d3nhu .Contract_title__bMBsC {
    margin-top: 1em;
    color: #006930;
    font-weight: 750;
}`, "",{"version":3,"sources":["webpack://./src/pages/contract/Contract.module.css"],"names":[],"mappings":"AAAA;;IAEI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,WAAW;;AAEf;;AAEA;IACI,iBAAiB;IACjB,WAAW;IACX,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,qBAAqB;IACrB,UAAU;IACV,eAAe;IACf,gBAAgB;IAChB,cAAc;AAClB;;AAEA;IACI,eAAe;IACf,cAAc;IACd,gBAAgB;AACpB","sourcesContent":[".container,\r\n.loadingRequest {\r\n    display: flex;\r\n    flex-direction: column;\r\n    justify-content: center;\r\n    align-items: center;\r\n    margin: 3em;\r\n\r\n}\r\n\r\n.container .subContainer {\r\n    max-width: 1300px;\r\n    width: 100%;\r\n    padding: 1em;\r\n    display: flex;\r\n    flex-direction: column;\r\n    min-height: 50vh;\r\n    margin-top: 5em;\r\n}\r\n\r\n.container .subContainer .return {\r\n    width: 15%;\r\n}\r\n\r\n.container .subContainer a {\r\n    display: flex;\r\n    align-items: center;\r\n    text-decoration: none;\r\n    gap: 0.5em;\r\n    font-size: 14px;\r\n    font-weight: 400;\r\n    color: #727588;\r\n}\r\n\r\n.container .subContainer .title {\r\n    margin-top: 1em;\r\n    color: #006930;\r\n    font-weight: 750;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Contract_container__nErhy`,
	"loadingRequest": `Contract_loadingRequest__Wio03`,
	"subContainer": `Contract_subContainer__d3nhu`,
	"return": `Contract_return__GJ4Q6`,
	"title": `Contract_title__bMBsC`
};
export default ___CSS_LOADER_EXPORT___;
