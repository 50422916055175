import React, { useEffect, useState } from 'react';
import styles from './YearSelector.module.css';

type Props = {
    years: number[];
    setSelectedYearChange(selectedYear: number): void;
};

const CustomYearSelector: React.FC<Props> = ({ years, setSelectedYearChange }) => {
    const yearSelected = years.sort((a, b) => a - b)[years.length - 1]
    const [selectedYear, setSelectedYear] = useState<number>(yearSelected);

    const handleYearChange = (year: number): void => {
        setSelectedYear(year);
        setSelectedYearChange(year);
    };


    const renderYears = (): number[] => {
        const sortedYears = years.sort((a, b) => a - b);
        if (sortedYears.length <= 4) {
            return sortedYears;
        } else {
            return sortedYears.slice(-4);
        }
    };

    const goToPreviousYear = (): void => {
        const currentIndex = years.indexOf(selectedYear);
        if (currentIndex > 0) {
            setSelectedYear(years[currentIndex - 1]);
            setSelectedYearChange(years[currentIndex - 1])
        }
    };

    const goToNextYear = (): void => {
        const currentIndex = years.indexOf(selectedYear);
        if (currentIndex < years.length - 1) {
            setSelectedYear(years[currentIndex + 1]);
            setSelectedYearChange(years[currentIndex + 1])
        }
    };

    useEffect(() => {
        setSelectedYear(yearSelected);
        setSelectedYearChange(yearSelected);
    }, [])

    return (
        <div className={styles.yearSelectorContainer}>
            <button
                className={styles.yearSelectorButtonLeft}
                onClick={goToPreviousYear}
                disabled={years.indexOf(selectedYear) === 0}
            >
                {'<'}
            </button>

            {renderYears().map((year) => (
                <button
                    key={year}
                    className={`${styles.yearSelectorButton} ${year === selectedYear ? styles.selected : ''}`}
                    onClick={() => handleYearChange(year)}
                >
                    {year}
                </button>
            ))}

            <button
                className={styles.yearSelectorButtonRight}
                onClick={goToNextYear}
                disabled={years.indexOf(selectedYear) === years.length - 1}
            >
                {'>'}
            </button>
        </div>
    );
};

export default CustomYearSelector;
