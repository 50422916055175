//react router dom
import { useParams, Link, useNavigate, useLocation } from 'react-router-dom';

//react
import { useContext, useEffect, useState } from 'react';

//css
import styles from './Contract.module.css';

//icons
import { BsArrowLeft } from 'react-icons/bs';

//components
import Tab from '../../components/tab/Tab';

//features
import ContractDoc from '../../features/contract/contract/ContractDoc';
import PaymentsSlip from '../../features/paymentsSlip/paymentsSlipPage/PaymentsSlip';
import Installments from '../../features/contract/installments/Installments';
import AnticipationInstallments from '../../features/contract/anticipationInstallments/AnticipationInstallments';
import Income from '../../features/contract/income/Income';

//service
import { ClientService } from '../../services/client/ClientService';

//context
import { ProfileInfoContext } from "../../hooks/ProfileUseContext";

//interfaces
import { IProfileInfo } from '../../interfaces/IProfileInfo';
import { IContract } from '../../interfaces/IContract';

//exception
import { ApiException } from '../../api/ApiException';

const Contract = () => {
    const { id } = useParams();

    const ERROR_REQUEST = "ERROR_REQUEST";
    const { setProfileValue } = useContext(ProfileInfoContext);

    const location = useLocation();
    const navigation = useNavigate();

    const [dataIsUpToDate, setDataIsUpToDate] = useState<boolean | string | undefined>(undefined);

    let contract: IContract | null = null
    let comingFromModalReceivedPaymentSlip: boolean | null = null
    if (location.state) {
        contract = location.state?.contract;
        comingFromModalReceivedPaymentSlip = location.state?.comingFromModalReceivedPaymentSlip || null;
    }
    useEffect(() => {

        if (!contract) navigation("/");

        ClientService.dataIsUpToDate().then((response) => {
            if (response instanceof ApiException) {
                setDataIsUpToDate(ERROR_REQUEST);
            } else {
                const ProfileInfo: IProfileInfo = response;
                setProfileValue(ProfileInfo);
                setDataIsUpToDate(ProfileInfo?.canUpdate);
                if (ProfileInfo.canUpdate) {
                    navigation("/")
                }
            }
        });
    }, [])

    return (
        <div className={styles.container}>
            <div className={styles.subContainer}>
                <Link to={`/home`} className={styles.return}><BsArrowLeft />Voltar para os contratos</Link>
                <Tab
                    tabsName={
                        [
                            'Contrato',
                            'Boletos',
                            'Informe de rendimentos',
                            'Extrato de parcelas',
                            'Antecipação de parcelas',
                        ]
                    }
                    tabsInfo={
                        [
                            <ContractDoc contract={contract} id={id} />,
                            <PaymentsSlip id={id} contract={contract}></PaymentsSlip>,
                            <Income id={id} contract={contract}></Income>,
                            <Installments id={id} contract={contract}></Installments>,
                            <AnticipationInstallments id={id} contract={contract}></AnticipationInstallments>,
                        ]}
                    numberPage={(comingFromModalReceivedPaymentSlip !== null && comingFromModalReceivedPaymentSlip === true ? 2 : null)}
                />
            </div>
        </div>
    )
}

export default Contract