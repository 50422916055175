import React, { useEffect, useState, useRef, useCallback } from 'react';
import styles from './ViewPdf.module.css';
import { FaFileDownload } from 'react-icons/fa';
import { File } from '../../utils/File';
import { MdOutlineMailOutline } from "react-icons/md";
import Loading from '../loading/Loading';

interface Props {
    base: string;
    fileName: string;
}

const ViewPdf: React.FC<Props> = ({ base, fileName }) => {
    const canvasRef = useRef<HTMLCanvasElement>(null);

    const [currentPage, setCurrentPage] = useState<number>(1);
    const [url, setUrl] = useState<string | null>(null);
    const [base64, setBase64] = useState<string | null>(null);
    const [name, setName] = useState<string | null>(null);
    const [pdfRef, setPdfRef] = useState<any>(null);

    const pdfjsLib: any = (window as any)['pdfjs-dist/build/pdf'];
    pdfjsLib.GlobalWorkerOptions.workerSrc = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.11.174/pdf.worker.js';

    const renderPage = useCallback((pageNum: number, pdf = pdfRef) => {
        pdf && pdf.getPage(pageNum).then(function (page: any) {
            const viewport = page.getViewport({ scale: 1.5 });
            const canvas = canvasRef.current;
            if (canvas) {
                canvas.height = viewport?.height;
                canvas.width = viewport?.width;
                const renderContext = {
                    canvasContext: canvas.getContext('2d'),
                    viewport: viewport
                };
                page.render(renderContext);
            }
        });
    }, [pdfRef]);

    const getDocument = useCallback(async () => {
        try {
            setUrl(`data:application/pdf;base64,${base}`);
            setName(fileName);
            setBase64(base);
            setCurrentPage(1); // Resetar currentPage para 1
        } catch (error) {
            console.log(error);
        }
    }, [base, fileName]);

    useEffect(() => {
        getDocument();
    }, [getDocument]);

    useEffect(() => {
        if (url) {
            const loadingTask = pdfjsLib.getDocument(url);
            loadingTask.promise.then((loadedPdf: any) => {
                setPdfRef(loadedPdf);
            }).catch((reason: any) => {
                console.error(reason);
            });
        }
    }, [url]);

    useEffect(() => {
        renderPage(currentPage, pdfRef);
    }, [pdfRef, currentPage, renderPage]);

    const nextPage = () => pdfRef && currentPage < pdfRef.numPages && setCurrentPage(currentPage + 1);

    const prevPage = () => currentPage > 1 && setCurrentPage(currentPage - 1);

    const renderPdf = () => {
        return (
            <>
                <div className={styles.containerCanvas} style={{}}>
                    <canvas ref={canvasRef}></canvas>
                </div>
                <div className={styles.buttons} >
                    <button onClick={prevPage} disabled={(currentPage === 1)}>Página anterior</button>
                    <button onClick={nextPage} disabled={(currentPage === pdfRef?.numPages)}>Próxima página</button>
                    {currentPage && pdfRef?.numPages && <>
                        {currentPage} - {pdfRef?.numPages}
                    </>}
                </div>
            </>
        )
    }

    return (
        <div className={styles.container}>
            {url && pdfRef ? (
                <>
                    {renderPdf()}
                </>
            ) : <Loading />}
        </div>
    )
}

export default ViewPdf;
