import styles from './Message.module.css'

type Props = {
    type: string,
    message: string
}

const Message: React.FC<Props> = ({ type, message }) => {
    return (
        <div className={`${styles.message}  ${styles[type]}`}>{message}</div>
    )
}

export default Message