import React from 'react'

//css
import styles from "./Login.module.css";

// components
import Input from '../../components/input/Input';

// reacptcha
import ReCAPTCHA from "react-google-recaptcha";

//react
import { useState } from 'react';

//interfaces
import { IRecaptchaGoogleResponse } from '../../interfaces/IRecaptchaGoogleResponse';


//service
import { RecaptchaGoogleService } from '../../services/recaptcha_google/RecaptchaGoogleService';

//exception
import { ApiException } from '../../api/ApiException';

//icons
import { FaEye, FaEyeSlash } from 'react-icons/fa';

type Props = {
    handleSubmitLogin(e: React.FormEvent, captcha: any): void
    loading: boolean,
    username: string
    setUsername(username: string): void
    Formatters: any,
    password: string,
    setPassword(password: string): void,
    handlePasswordRecovery(e: React.FormEvent): void,
    message: string,
    Message: any,
    handleFisrtAccess(e: React.FormEvent): void,
    attemps: number,
    setLoading(loading: boolean): void,
    seePassword(id: string): string
    setMessage(message: string): void

}
const SeeLoginScreen: React.FC<Props> = ({
    handleSubmitLogin,
    loading,
    username,
    setUsername,
    Formatters,
    password,
    setPassword,
    handlePasswordRecovery,
    message,
    Message,
    handleFisrtAccess,
    attemps,
    setLoading,
    seePassword,
    setMessage
}) => {
    const key_site = process.env.REACT_APP_RECAPTCHA_KEY_SITE;
    const key_secret = process.env.REACT_APP_RECAPTCHA_KEY_SECRET;

    const [captchaValue, setCaptchaValue] = useState<any>();
    const [passwordId, setPasswordId] = useState("password")

    const [requiredUsername, setRequiredUsername] = useState<boolean>(false);
    const [requiredPassword, setRequiredPassword] = useState<boolean>(false);

    let captcha: any


    const setCaptchaRef = (ref: any) => {
        if (ref) {
            return captcha = ref;
        }
    };

    const onChange = (value: any) => {
        setCaptchaValue(value)
        setMessage('');
    }

    const handleInvalidInput = (event: any) => {
        event.preventDefault();
        if (!event.target.value)
            event.target.classList.add('required');
        if (event.target.name === 'username') setRequiredUsername(true);
        if (event.target.name === 'password') setRequiredPassword(true);
    };

    const handleInputBlur = () => {
    };
    return (
        <form onSubmit={(e: React.FormEvent) => {
            e.preventDefault();
            if (!username || !password) {
                return;
            }
            setLoading(true);
            if (attemps > 1 && key_site && key_secret) {
                const dados = {
                    response: captchaValue,
                };
                RecaptchaGoogleService.sendTokenRecaptcha(dados).then((response) => {
                    if (response instanceof ApiException) {
                        captcha?.reset();
                        setCaptchaValue(null);
                        setLoading(false);
                    } else {
                        const recaptchaGoogleResponse: IRecaptchaGoogleResponse = response;
                        if (recaptchaGoogleResponse.success) {
                            handleSubmitLogin(e, captcha);
                            setCaptchaValue(null);
                        }
                    }
                })
            } else {
                handleSubmitLogin(e, null);
            }
        }}>
            <div className={styles.title}>
                <span>Olá, prazer ter você aqui</span>
            </div>
            <label>CPF/CNPJ <span className={styles.asterisk}>*</span></label>
            <Input
                name='username'
                disabled={loading}
                placeholder="CPF/CNPJ"
                value={username}
                onChange={(e) =>
                    setUsername(Formatters.formatterCpfCnpj(e.target.value))
                }
                required
                onInvalid={handleInvalidInput}
                onBlur={handleInputBlur}
            />
            {requiredUsername && !username && <p className='inputRequired'>CPF/CNPJ obrigatório</p>}


            <label>Senha<span className={styles.asterisk}>*</span></label>
            <Input
                name='password'
                id="passwordId"
                disabled={loading}
                type="password"

                placeholder="Insira sua senha"
                value={password}
                maxLength={15}
                onChange={(e) =>
                    setPassword(e.target.value)
                }
                required
                onInvalid={handleInvalidInput}
                onBlur={handleInputBlur}
            />
            <div className={styles.show_password} onClick={() => setPasswordId(seePassword('passwordId'))}>
                {passwordId === "password" && <FaEye />}
                {passwordId !== "password" && <FaEyeSlash />}
            </div>
            {requiredPassword && !password && <span className='inputRequiredPassword'>Senha obrigatório</span>}
            <div className={styles.sub_items_footer}>
                <div className={styles.sub_items}>
                    <span onClick={handlePasswordRecovery}>Esqueceu sua senha?</span>
                </div>
            </div>
            <div className={styles.captcha}>
                {attemps > 1 && key_site && key_secret && (
                    <ReCAPTCHA
                        ref={(r) => setCaptchaRef(r)}
                        className={styles.captcha}
                        sitekey={key_site}
                        onChange={onChange}
                    />
                )}
            </div>
            {message && <Message message={message} type="error_login" />}
            {attemps > 1 && <p className={styles.central_message}>Em caso de duvida, você pode ligar para a central do banco BBC Digital através do número <span className={styles.central_message_span}>0800 771 4080</span></p>}
            <div className={styles.confirm_button}>
                {attemps > 1 && key_site && key_secret ? (
                    <>
                        {loading && <button disabled>Aguarde...</button>}
                        {captchaValue && !loading && <button>Entrar</button>}
                        {!captchaValue && !loading && <button disabled>Preencha o captcha</button>}
                    </>
                ) : (
                    <>
                        {loading && <button disabled>Aguarde...</button>}
                        {!loading && <button>Entrar</button>}
                    </>
                )}
            </div>
            <div className={styles.sub_items_footer}>
                <span>Primeira vez por aqui?</span><span className={styles.sub_items} onClick={handleFisrtAccess}> Cadastre-se</span>
            </div>
        </form>
    )
}

export default SeeLoginScreen