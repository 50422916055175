// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ErrorSuccess_container__jJi67 {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    gap: 1em;
    width: 100%;    
}

.ErrorSuccess_container__jJi67 svg {
    width: 25px;
    height: 25px;
    color: #ffcc00;
}
.ErrorSuccess_container__jJi67 .ErrorSuccess_containerMessage__mXrRo {
    width: 100%;
    gap: .7em;
    font-size: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;

    border: 1px solid #eaeaea;
    padding: 0.5em;
    margin-bottom: 1em;
}`, "",{"version":3,"sources":["webpack://./src/components/errorSuccess/ErrorSuccess.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,uBAAuB;IACvB,mBAAmB;IACnB,cAAc;IACd,QAAQ;IACR,WAAW;AACf;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,cAAc;AAClB;AACA;IACI,WAAW;IACX,SAAS;IACT,eAAe;IACf,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,kBAAkB;IAClB,mBAAmB;;IAEnB,yBAAyB;IACzB,cAAc;IACd,kBAAkB;AACtB","sourcesContent":[".container {\r\n    display: flex;\r\n    flex-direction: column;\r\n    text-align: center;\r\n    justify-content: center;\r\n    align-items: center;\r\n    margin: 0 auto;\r\n    gap: 1em;\r\n    width: 100%;    \r\n}\r\n\r\n.container svg {\r\n    width: 25px;\r\n    height: 25px;\r\n    color: #ffcc00;\r\n}\r\n.container .containerMessage {\r\n    width: 100%;\r\n    gap: .7em;\r\n    font-size: 12px;\r\n    display: flex;\r\n    flex-direction: column;\r\n    justify-content: center;\r\n    text-align: center;\r\n    align-items: center;\r\n\r\n    border: 1px solid #eaeaea;\r\n    padding: 0.5em;\r\n    margin-bottom: 1em;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `ErrorSuccess_container__jJi67`,
	"containerMessage": `ErrorSuccess_containerMessage__mXrRo`
};
export default ___CSS_LOADER_EXPORT___;
