//css
import styles from "./Main.module.css";

//react
import { useEffect, useState, useContext } from "react";

//services
import { ClientService } from "../../services/client/ClientService";
import { ContractService } from "../../services/contract/ContractService";

//exception
import { ApiException } from "../../api/ApiException";

//components
import Loading from "../../components/loading/Loading";
import Input from "../../components/input/Input";
import Error from "../../components/error/Error";

//features
import ContractCards from "../../features/contract/contractCards/ContractCards";
import ModalUpdateRegistration from "../../features/cadastralData/modalUpdateRegistration/ModalUpdateRegistration";
import ModalReceivedPaymentSlip from "../../features/paymentsSlip/modalReceivedPaymentSlip/ModalReceivedPaymentSlip";

// interfaces
import { IProfileInfo } from "../../interfaces/IProfileInfo";
import { IContract } from "../../interfaces/IContract";

//context
import { ProfileInfoContext } from "../../hooks/ProfileUseContext";
import { PaymentSlipService } from "../../services/paymentSlip/PaymentSlipService";


const Main: React.FC = () => {

  const { setProfileValue } = useContext(ProfileInfoContext);

  const [dataIsUpToDate, setDataIsUpToDate] = useState<boolean | string | undefined>(undefined);
  const [receivedPaymentSlip, setReceivedPaymentSlip] = useState<boolean | undefined | null>(undefined);
  const [contracts, setContracts] = useState<IContract[] | []>([]);
  const [loadingContracts, setLoadingContracts] = useState<boolean>(false)

  const [open, setOpen] = useState(false);
  const [openReceivedPaymentSlipModal, setOpenReceivedPaymentSlipModal] = useState(true);
  const [byTheButton, setByTheButton] = useState(false);

  const [searchValue, setSearchValue] = useState('');

  const [hasError, setHasError] = useState<boolean>(false);
  const [contract, setContract] = useState<IContract>();



  const [messageError, setMessageError] = useState<string>('');
  const onCloseModalReceivedPaymentSlip = () => {
    setOpenReceivedPaymentSlipModal(false);
  }

  const onCloseModal = () => {
    setByTheButton(false);
    setOpen(false);
  }

  const handleModalUpdateRegistration = () => {
    setByTheButton(true);
    setOpen(true);
  }
  const filteredContracts: IContract[] | [] | undefined = searchValue ? contracts?.filter((contract) => {
    return contract.bem.toLowerCase().includes(searchValue.toLowerCase()) ||
      contract.contractCode.toLowerCase().includes(searchValue.toLowerCase())
  }) : contracts

  const ERROR_REQUEST = "ERROR_REQUEST";

  const viewVerify = async (contracts: IContract[]) => {
    PaymentSlipService.viewVerify(null).then((response) => {
      if (response instanceof ApiException) {
        setHasError(true)
        setMessageError(response.message);
        setLoadingContracts(false);
      } else {
        const finalContract: IContract | undefined = contracts.find((contract: IContract) => contract?.contractCode === response?.contractCode);
        setReceivedPaymentSlip(response.receivedPaymentSlip);
        setContract(finalContract);
        setLoadingContracts(false);
      }
    })
  }

  useEffect(() => {
    ClientService.dataIsUpToDate().then((response) => {
      if (response instanceof ApiException) {
        setDataIsUpToDate(ERROR_REQUEST);
      } else {
        const ProfileInfo: IProfileInfo = response;
        setProfileValue(ProfileInfo);
        setDataIsUpToDate(ProfileInfo.canUpdate);
        setOpen(ProfileInfo.canUpdate);
      }
    });
  }, []);


  useEffect(() => {
    setLoadingContracts(true)
    ContractService.getAllContracts().then((response) => {
      if (response instanceof ApiException) {
        setHasError(true)
        setDataIsUpToDate(ERROR_REQUEST);
        setMessageError(response.message);
        setLoadingContracts(false);
      } else {
        setContracts(response)
        viewVerify(response);
      }
    })
  }, [])

  if (dataIsUpToDate === undefined || loadingContracts) return <Loading />;

  return (
    <main className={styles.container}>
      <div className={styles.subContainer}>
        {!hasError ? (
          <>
            <h2 className={styles.title}>Meus contratos</h2>
            <div className={styles.headerPage}>
              <div className={styles.filterPage}>
                <p>Contrato/Veículo</p>
                <Input type="text" placeholder="Insira sua pesquisa" onChange={(e) => setSearchValue(e.target.value)} />
              </div>
              <button onClick={handleModalUpdateRegistration} className={styles.updateDataRegistration}>Atualizar dados cadastrais</button>
            </div>
            {filteredContracts && filteredContracts?.length > 0 && <ContractCards contracts={filteredContracts || []} />}
            {filteredContracts && filteredContracts?.length === 0 && <p className={styles.noneContract}>Nenhum contrato encontrado!</p>}
          </>
        ) : (
          <Error message={messageError} />
        )}
      </div>
      {open && !hasError && (
        <ModalUpdateRegistration
          open={open}
          byTheButton={byTheButton}
          onCloseModal={onCloseModal}
        />
      )}
      {receivedPaymentSlip !== undefined &&
        receivedPaymentSlip === null &&
        !dataIsUpToDate &&
        openReceivedPaymentSlipModal &&
        contract && (
          <ModalReceivedPaymentSlip
            open={openReceivedPaymentSlipModal}
            onCloseModal={onCloseModalReceivedPaymentSlip}
            contract={contract}
          />
        )}
    </main>
  );
};

export default Main;
