// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Input_inputBox__dwKmd {
    display: flex;
    flex-direction: column;
}

.Input_container__jhQDf {
    height: 42px;
    left: 0%;

    border: 1px solid #CCD5DA;
    border-radius: 8px;
    padding-top: 0;
    padding-left: 1em;
    position: relative;

    background: #FFFFFF;
    border: 1px solid #CED4DA;
    box-shadow: 0px 2px 2px rgba(33, 37, 41, 0.06), 0px 0px 1px rgba(33, 37, 41, 0.08);

}


.Input_container__jhQDf:disabled {
    cursor: no-drop;
}`, "",{"version":3,"sources":["webpack://./src/components/input/Input.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,YAAY;IACZ,QAAQ;;IAER,yBAAyB;IACzB,kBAAkB;IAClB,cAAc;IACd,iBAAiB;IACjB,kBAAkB;;IAElB,mBAAmB;IACnB,yBAAyB;IACzB,kFAAkF;;AAEtF;;;AAGA;IACI,eAAe;AACnB","sourcesContent":[".inputBox {\r\n    display: flex;\r\n    flex-direction: column;\r\n}\r\n\r\n.container {\r\n    height: 42px;\r\n    left: 0%;\r\n\r\n    border: 1px solid #CCD5DA;\r\n    border-radius: 8px;\r\n    padding-top: 0;\r\n    padding-left: 1em;\r\n    position: relative;\r\n\r\n    background: #FFFFFF;\r\n    border: 1px solid #CED4DA;\r\n    box-shadow: 0px 2px 2px rgba(33, 37, 41, 0.06), 0px 0px 1px rgba(33, 37, 41, 0.08);\r\n\r\n}\r\n\r\n\r\n.container:disabled {\r\n    cursor: no-drop;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputBox": `Input_inputBox__dwKmd`,
	"container": `Input_container__jhQDf`
};
export default ___CSS_LOADER_EXPORT___;
