// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ViewPdf_container__3JviV {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}


.ViewPdf_buttons__hqNjP {
    display: flex;
    align-items: center;
    text-align: center;
    gap: 1em;
    float: left;
    margin-bottom: 1em;
    margin-top: 1em;
    flex-wrap: wrap;
}

.ViewPdf_buttons__hqNjP svg {
    width: 15px;
    height: 15px;
}

.ViewPdf_containerCanvas__bXJ\\+W {
    border: 1px solid black;
    width: 100%;
}


.ViewPdf_buttons__hqNjP button {
    text-align: center;
    margin-top: 1.6em;
    height: 42px;
    background-color: #006930;
    color: #FEFEFE;
    font-weight: 600;
    font-size: 14px;
    border-radius: 8px;
}

.ViewPdf_buttons__hqNjP button:hover {
    opacity: 1;
}

.ViewPdf_buttons__hqNjP button {
    padding: 1em;
}

.ViewPdf_buttons__hqNjP button:disabled {
    cursor: not-allowed;
    opacity: .5;
}

canvas {
    width: 100%;
    pointer-events: none;
}

@media (max-width: 600px) {

    .ViewPdf_buttons__hqNjP button {
        min-width: 100%;
    }
}

@media (max-width: 387px) {

    .ViewPdf_buttons__hqNjP button {
        font-size: 10px;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/viewPdf/ViewPdf.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,WAAW;AACf;;;AAGA;IACI,aAAa;IACb,mBAAmB;IACnB,kBAAkB;IAClB,QAAQ;IACR,WAAW;IACX,kBAAkB;IAClB,eAAe;IACf,eAAe;AACnB;;AAEA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,uBAAuB;IACvB,WAAW;AACf;;;AAGA;IACI,kBAAkB;IAClB,iBAAiB;IACjB,YAAY;IACZ,yBAAyB;IACzB,cAAc;IACd,gBAAgB;IAChB,eAAe;IACf,kBAAkB;AACtB;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,mBAAmB;IACnB,WAAW;AACf;;AAEA;IACI,WAAW;IACX,oBAAoB;AACxB;;AAEA;;IAEI;QACI,eAAe;IACnB;AACJ;;AAEA;;IAEI;QACI,eAAe;IACnB;AACJ","sourcesContent":[".container {\r\n    display: flex;\r\n    flex-direction: column;\r\n    justify-content: center;\r\n    align-items: center;\r\n    width: 100%;\r\n}\r\n\r\n\r\n.buttons {\r\n    display: flex;\r\n    align-items: center;\r\n    text-align: center;\r\n    gap: 1em;\r\n    float: left;\r\n    margin-bottom: 1em;\r\n    margin-top: 1em;\r\n    flex-wrap: wrap;\r\n}\r\n\r\n.buttons svg {\r\n    width: 15px;\r\n    height: 15px;\r\n}\r\n\r\n.containerCanvas {\r\n    border: 1px solid black;\r\n    width: 100%;\r\n}\r\n\r\n\r\n.buttons button {\r\n    text-align: center;\r\n    margin-top: 1.6em;\r\n    height: 42px;\r\n    background-color: #006930;\r\n    color: #FEFEFE;\r\n    font-weight: 600;\r\n    font-size: 14px;\r\n    border-radius: 8px;\r\n}\r\n\r\n.buttons button:hover {\r\n    opacity: 1;\r\n}\r\n\r\n.buttons button {\r\n    padding: 1em;\r\n}\r\n\r\n.buttons button:disabled {\r\n    cursor: not-allowed;\r\n    opacity: .5;\r\n}\r\n\r\ncanvas {\r\n    width: 100%;\r\n    pointer-events: none;\r\n}\r\n\r\n@media (max-width: 600px) {\r\n\r\n    .buttons button {\r\n        min-width: 100%;\r\n    }\r\n}\r\n\r\n@media (max-width: 387px) {\r\n\r\n    .buttons button {\r\n        font-size: 10px;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `ViewPdf_container__3JviV`,
	"buttons": `ViewPdf_buttons__hqNjP`,
	"containerCanvas": `ViewPdf_containerCanvas__bXJ+W`
};
export default ___CSS_LOADER_EXPORT___;
