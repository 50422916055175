// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Error_container__XGPum {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    gap: 1em;

}

.Error_container__XGPum svg {
    width: 50px;
    height: 50px;
    color: #ffcc00;
}

.Error_container__XGPum .Error_containerMessage__\\+4o97 {
    gap: .7em;
    font-size: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
}

.Error_container__XGPum .Error_containerMessage__\\+4o97 button {

    background-color: #006930;
    color: #FEFEFE;
    font-size: 16px;
    font-weight: 500;
    width: 47%;
}

.Error_container__XGPum .Error_containerMessage__\\+4o97 .Error_warning__2\\+jnK {
    color: #ff0000;
}`, "",{"version":3,"sources":["webpack://./src/components/error/Error.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,uBAAuB;IACvB,mBAAmB;IACnB,cAAc;IACd,QAAQ;;AAEZ;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,cAAc;AAClB;;AAEA;IACI,SAAS;IACT,eAAe;IACf,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;;IAEI,yBAAyB;IACzB,cAAc;IACd,eAAe;IACf,gBAAgB;IAChB,UAAU;AACd;;AAEA;IACI,cAAc;AAClB","sourcesContent":[".container {\r\n    display: flex;\r\n    flex-direction: column;\r\n    text-align: center;\r\n    justify-content: center;\r\n    align-items: center;\r\n    margin: 0 auto;\r\n    gap: 1em;\r\n\r\n}\r\n\r\n.container svg {\r\n    width: 50px;\r\n    height: 50px;\r\n    color: #ffcc00;\r\n}\r\n\r\n.container .containerMessage {\r\n    gap: .7em;\r\n    font-size: 12px;\r\n    display: flex;\r\n    flex-direction: column;\r\n    justify-content: center;\r\n    text-align: center;\r\n    align-items: center;\r\n}\r\n\r\n.container .containerMessage button {\r\n\r\n    background-color: #006930;\r\n    color: #FEFEFE;\r\n    font-size: 16px;\r\n    font-weight: 500;\r\n    width: 47%;\r\n}\r\n\r\n.container .containerMessage .warning {\r\n    color: #ff0000;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Error_container__XGPum`,
	"containerMessage": `Error_containerMessage__+4o97`,
	"warning": `Error_warning__2+jnK`
};
export default ___CSS_LOADER_EXPORT___;
