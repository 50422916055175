//react
import { useEffect, useState } from 'react';

//interfaces
import { IContract } from '../../../interfaces/IContract';
import { IBankSlipStatus } from '../../../interfaces/IBankSlipStatus';
import { IInstallmentsExtractInfo } from '../../../interfaces/IInstallmentsExtractInfo';


//css
import styles from './Installments.module.css';

//components
import Loading from '../../../components/loading/Loading';
import ViewPdf from '../../../components/viewPdf/ViewPdf';
import Error from '../../../components/error/Error';

//icons
import { FaFileDownload } from 'react-icons/fa';
import { MdOutlineMailOutline } from 'react-icons/md';

//services
import { BankSlipService } from '../../../services/bankSlip/BankSlipService';

//api
import { ApiException } from '../../../api/ApiException';

//utils
import { Formatters } from '../../../utils/Formatters';
import { Toast } from '../../../utils/Toast';
import { File } from '../../../utils/File';

type Props = {
    id: string | undefined
    contract: IContract | null
}
const Installments: React.FC<Props> = ({ id, contract }) => {
    const [data, setData] = useState<any | []>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [hasError, setHasError] = useState<boolean>(false);
    const [messageError, setMessageError] = useState<string>('');
    const [bankSlipStatus, setBankSlipStatus] = useState<IBankSlipStatus[]>([]);
    const [status, setStatus] = useState<string>('');

    const [installmentInfo, setinstallmentInfo] = useState<IInstallmentsExtractInfo>();
    const [installment, setinstallment] = useState<number[]>([]);
    const [initialInstallment, setInitialInstallment] = useState<number>();
    const [finalInstallment, setFinalInstallment] = useState<number>();

    const downloadPDF = () => {
        if (data && data?.base64 && data?.fileName) {
            File.downloadPdf(data?.base64, data?.fileName);
        }
    }

    const sendEmail = async () => {
        setLoading(true);
        BankSlipService.sendEmailInstallmentsExtract(contract?.contractCode || '', contract?.dataOrigin || '', String(initialInstallment) || '', String(finalInstallment) || '', status || null).then((response) => {
            if (response instanceof ApiException) {
                Toast.error(response.message)
                setLoading(false)
            } else {
                Toast.success("Enviado com sucesso!");
                setLoading(false)
            }
        })
    }

    const getAllStatus = async () => {
        BankSlipService.getAllStatus().then((response) => {
            if (response instanceof ApiException) {
                setMessageError(response.message);
            } else {
                setBankSlipStatus(response);
            }
        })
    }
    const getInstallmentsExtract = async () => {
        BankSlipService.getInstallmentsExtract(contract?.contractCode || '', contract?.dataOrigin || '').then((response) => {
            if (response instanceof ApiException) {
                setHasError(true)
                setMessageError(response.message);
                setLoading(false);
            } else {
                if (Array.isArray(response)) {
                    return
                }
                else if (response.totalNumber) {
                    setinstallment(Formatters.numberToArray(response.totalNumber));
                    setInitialInstallment(1);
                    setFinalInstallment(response.totalNumber);
                    setinstallmentInfo(response);
                }
                setLoading(false);
            }
        })
    }

    const getDownloadInstallmentsExtract = async () => {

        if (!initialInstallment || !finalInstallment) return null;

        if (initialInstallment >= finalInstallment) {
            Toast.error("Parcela inicial deve ser menor que parcela final")
            setData(null);
            return;
        }
        setLoading(true);
        BankSlipService.getDownloadInstallmentsExtract(contract?.contractCode || '', contract?.dataOrigin || '', String(initialInstallment) || '', String(finalInstallment) || '', status || null).then((response) => {
            if (response instanceof ApiException) {
                setHasError(true);
                Toast.error(response.message)
                setMessageError(response.message);
                setLoading(false);
                setData(null);
            } else {
                setData(response);
                setLoading(false);
            }
        })
    }

    useEffect(() => {
        setLoading(true);
        getAllStatus();
        getInstallmentsExtract();
    }, [])

    useEffect(() => {
        if (finalInstallment || initialInstallment || status) {

            getDownloadInstallmentsExtract();

        }
    }, [finalInstallment, initialInstallment, status])
    return (
        <>
            <div className={styles.container}>
                {loading && <Loading />}
                <div className={styles.top}>
                    <h2 className={styles.tittle}>Extrato de parcelas</h2>
                    <div className={styles.informations}>
                        <label className={styles.information}>
                            Contrato
                            <strong style={{ color: "#006930" }}>{contract?.contractCode}</strong>
                        </label>
                        <label className={styles.information}>
                            Número de parcelas Total
                            <strong>{installmentInfo?.totalNumber}</strong>
                        </label>
                        <label className={styles.information}>
                            Número de parcelas em Aberto
                            <strong>{installmentInfo?.totalOpenNumber}</strong>
                        </label>
                        <label className={styles.information}>
                            Número de parcelas Pagas
                            <strong>{installmentInfo?.totalPaidNumber}</strong>
                        </label>
                        <label className={styles.information}>
                            Saldo Devedor
                            <strong>{installmentInfo?.totalOpenValue && Formatters.formatMoneyInRealBrazil(installmentInfo?.totalOpenValue)}</strong>
                        </label>
                    </div>
                </div>
                <hr />
                <div className={styles.filter}>
                    <div className={styles.filters}>
                        <label >
                            Parcela inicial
                            <select value={initialInstallment} onChange={(e) => setInitialInstallment(Number(e.target.value))}>
                                {installment && installment.map((installment: number) => {
                                    return <option key={installment} value={installment}> {installment}</option>
                                })}
                            </select>
                        </label>
                        <label >
                            Parcela final
                            <select value={finalInstallment} onChange={(e) => setFinalInstallment(Number(e.target.value))}>
                                {installment && installment.map((installment: number) => {
                                    return <option key={installment} value={installment}> {installment}</option>
                                })}
                            </select>
                        </label>
                        <label >
                            Status
                            <select value={status} onChange={(e) => setStatus(e.target.value)}>
                                <option value="" >Todos</option>
                                {bankSlipStatus && bankSlipStatus.map((bankSlip: IBankSlipStatus, index) => {
                                    return <option key={bankSlip.id || index} value={bankSlip?.code}> {bankSlip?.description}</option>
                                })}
                            </select>
                        </label>
                    </div>
                    {data && (
                        <div className={styles.actionButtons}>
                            {data.base64 && data.fileName && <button className={styles.download} onClick={downloadPDF}><FaFileDownload />Baixar</button>}
                            {sendEmail && <button className={styles.sendEmail} onClick={sendEmail}><MdOutlineMailOutline />Enviar para o E-mail</button>}
                        </div>

                    )}
                    {Array.isArray(data) && data.length === 0 && (
                        "Não existem parcelas dentro dos critérios selecionados. Por favor, altere o filtro."
                    )}


                </div>

                {data && (
                    <div>
                        <br />
                        <div style={{ width: "100%", paddingBottom: "56.25%", position: "relative" }}>
                            {data && (
                                <ViewPdf
                                    base={data.base64}
                                    fileName={data.fileName}
                                />
                            )}
                        </div>
                    </div>
                )}
                <br />
                {messageError}
                {/*hasError && <Error message={messageError} />*/}
            </div >

        </>
    )
}

export default Installments