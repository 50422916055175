//components
import Input from '../../../components/input/Input';
import Loading from '../../../components/loading/Loading';
import Error from '../../../components/error/Error';

//styles
import styles from './PaymentsSlip.module.css';

//table
import PaymentsSlipTable from '../paymentsSlipTable/PaymentsSlipTable';

// paginate 
import ReactPaginate from "react-paginate";

//react
import { useState, useEffect, useCallback } from 'react';

//icons
import { RiArrowDropRightLine, RiArrowDropLeftLine, RiArrowRightDoubleLine, RiArrowLeftDoubleFill } from 'react-icons/ri'

//services
import { BankSlipService } from '../../../services/bankSlip/BankSlipService';
import { ContractService } from '../../../services/contract/ContractService';

//exception
import { ApiException } from '../../../api/ApiException';

//interfaces
import { IBankSlipStatus } from '../../../interfaces/IBankSlipStatus';
import { IContract } from '../../../interfaces/IContract';
import { IContent } from '../../../interfaces/IPage';

//moment
import moment from 'moment';

//utils
import { File } from '../../../utils/File';
import { Toast } from '../../../utils/Toast';

type Props = {
    id: string | undefined
    contract: IContract | null
}
const PaymentsSlip: React.FC<Props> = ({ id, contract }) => {

    const [page, setPage] = useState(0)
    const [pageCount, setPageCount] = useState(0);
    const [totalElements, setTotalElements] = useState(0);

    const [data, setData] = useState<IContent[]>([]);

    const [bankSlipStatus, setBankSlipStatus] = useState<IBankSlipStatus[]>([]);
    const [selected, setSelected] = useState<number[]>([]);
    const [selectedAll, setSelectedAll] = useState<boolean>(false);
    const [hasDownload, setHasDownload] = useState<boolean>(false);

    const [loading, setLoading] = useState<boolean>(false);

    const [hasError, setHasError] = useState<boolean>(false);
    const [messageError, setMessageError] = useState<string>('');

    //filtro
    const [competenceDate, setCompetenceDate] = useState<string>();
    const [status, setStatus] = useState<string>('');
    const [currentStatus, setCurrentStatus] = useState<string>('');
    const [currentCompetenceDate, setCurrentCompetenceDate] = useState<string>();

    const getAllInstallments = useCallback(async (status: string, competenceDate: string, page: number) => {
        setLoading(true);
        await ContractService.getAllInstallments({
            contractCode: contract?.contractCode,
            originCode: contract?.dataOrigin,
            dueDate: competenceDate ? moment(competenceDate).format("yyyy-MM-DD") : '',
            status: status,
            page: page,
            size: 10
        }).then((response) => {
            if (response instanceof ApiException) {
                setHasError(true);
                setPageCount(0);
                setTotalElements(0);
                setData([]);
                setHasDownload(false);
            } else {
                setHasError(false);
                setPageCount(response?.totalPages);
                setTotalElements(response?.totalElements);
                setData(response?.content);
                setHasDownload(response?.hasDownload || false);
            }
            setLoading(false);
        })
    }, [])

    const handleFilterClick = () => {
        setPage(0);
        setCurrentStatus(status)
        setCurrentCompetenceDate(competenceDate);
        getAllInstallments(status, competenceDate || '', 0);
    }

    const handlePageClick = (data: any) => {
        setPage(data.selected);
        getAllInstallments(currentStatus, currentCompetenceDate || '', data.selected);
    };
    const lastPage = () => {
        setPage(pageCount - 1)
        getAllInstallments(currentStatus, currentCompetenceDate || '', pageCount - 1);
    }

    const firstPage = () => {
        setPage(0)
        getAllInstallments(currentStatus, currentCompetenceDate || '', 0);
    }

    const downloadAllDocument = (): void => {
        setLoading(true);
        BankSlipService.getDownload(
            contract?.contractCode || '',
            selected.length === 0 || selectedAll ? "0" : selected.join(","),
            contract?.dataOrigin || '',
            competenceDate || '',
            status
        ).then((response) => {
            if (response instanceof ApiException) {
                setMessageError(response.message);
                Toast.error(response.message)
            } else {
                const base64 = response.base64;
                File.downloadPdf(base64, response?.fileName);
                Toast.success("Baixado com sucesso!")
            }
            setLoading(false);
        })
    }

    const downloadDocument = (data: IContent): void => {
        setLoading(true);
        BankSlipService.getDownload(contract?.contractCode || '', String(data.number), contract?.dataOrigin || '', competenceDate || '', status).then((response) => {
            if (response instanceof ApiException) {
                setMessageError(response.message);
                Toast.error(response.message)
            } else {
                const base64 = response.base64;
                File.downloadPdf(base64, response?.fileName);
                Toast.success("Baixado com sucesso!")
            }
            setLoading(false);
        })
    }
    const sendDocumentEmail = (data: IContent): void => {
        setLoading(true);
        BankSlipService.sendEmail(contract?.contractCode || '', String(data.number), contract?.dataOrigin || '', competenceDate || '', status).then((response) => {
            if (response instanceof ApiException) {
                setMessageError(response.message);
                Toast.error(response.message)
            } else {
                Toast.success("Enviado com sucesso!")
            }
            setLoading(false);
        })
    }

    useEffect(() => {
        BankSlipService.getAllStatus().then((response) => {
            if (response instanceof ApiException) {
                setMessageError(response.message);
            } else {
                setBankSlipStatus(response);
            }
        })
    }, [])

    useEffect(() => {
        getAllInstallments(currentStatus, '', 0);
    }, [getAllInstallments])

    return (
        <>
            {loading && <Loading />}
            <div className={styles.container}>
                <div className={styles.top}>
                    <h2 className={styles.tittle}>Boletos</h2>
                    <div className={styles.informations}>
                        <label className={styles.information}>
                            Contrato
                            <strong style={{ color: "#006930" }}>{contract?.contractCode}</strong>
                        </label>
                    </div>
                </div>
                <hr />
                <div className={styles.filter}>
                    <label >
                        Status da Fatura
                        <select value={status} onChange={(e) => setStatus(e.target.value)}>
                            <option value="" >Todos</option>
                            {bankSlipStatus && bankSlipStatus.map((bankSlip: IBankSlipStatus, index) => {
                                return <option key={bankSlip.id || index} value={bankSlip?.code}> {bankSlip?.description}</option>
                            })}
                        </select>
                    </label>
                    <label>
                        Competência
                        <Input type='date' value={competenceDate || ""} onChange={(e) => setCompetenceDate(e.target.value)} />
                    </label>
                    <input className={styles.btnFilter} type="button" value="Filtrar" onClick={handleFilterClick} />
                    <button
                        className={styles.btnAllDocuments}
                        onClick={downloadAllDocument}
                        disabled={!hasDownload || (selected.length === 0 && !selectedAll)}
                    >
                        Baixar documentos
                    </button>
                </div>
                <div className={styles.tableContainer}>
                    {data && data.length > 0 && (
                        <PaymentsSlipTable
                            data={data}
                            downloadDocument={downloadDocument}
                            sendDocumentEmail={sendDocumentEmail}
                            selected={selected}
                            setSelected={setSelected}
                            selectedAll={selectedAll}
                            setSelectedAll={setSelectedAll}
                        />
                    )}
                </div>
                {hasError && <Error message={messageError} />}
                {totalElements > 10 && (
                    <div className='paginationContainer'>
                        <div
                            onClick={firstPage}
                            className={`first-page ${page === 0 ? 'disabled' : ''}`}>
                            <RiArrowLeftDoubleFill />
                        </div>
                        <ReactPaginate
                            previousLabel={<div className={`first-page ${page === 0 ? 'disabled' : ''}`}><RiArrowDropLeftLine /></div>}
                            nextLabel={<div className={`last-page ${page + 1 === pageCount ? 'disabled' : ''}`}><RiArrowDropRightLine /></div>}
                            breakLabel={"..."}
                            pageCount={pageCount}
                            marginPagesDisplayed={1}
                            pageRangeDisplayed={3}
                            onPageChange={handlePageClick}
                            containerClassName={"pagination"}
                            pageClassName={"page-item"}
                            pageLinkClassName={"page-link"}
                            breakClassName={"page-item"}
                            breakLinkClassName={"page-link"}
                            activeClassName={"active"}
                            activeLinkClassName={'active'}
                            forcePage={page}
                        />
                        <div
                            onClick={lastPage}
                            className={`last-page ${page + 1 === pageCount ? 'disabled' : ''}`}>
                            <RiArrowRightDoubleLine />
                        </div>
                    </div>
                )}
            </div >
        </>
    )
}

export default PaymentsSlip