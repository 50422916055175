// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Footer_footer__QwD\\+f {
    display: flex;
    justify-content: flex-start;
    min-height: 88px;
    align-items: center;
    padding-left: 4em;
    padding-right: 4em;

    font-size: 14px;
    font-weight: 400;
    color: #727588;
    margin-top: auto;

    flex-wrap: wrap;
    -webkit-user-select: none;
            user-select: none;
}

.Footer_footer__QwD\\+f div {
    display: flex;
    gap: .5em;
}

.Footer_footer__QwD\\+f div svg {
    cursor: pointer;
    width: 20px;
    height: 20px;
    color: #3A683D;
}


@media (min-width: 1300px) and (min-height: 820px) {
    .Footer_fixed__-KKHZ {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 0;
    }

    .Footer_fixed__-KKHZ div {
        padding-right: 4em;
    }

    .Footer_fixed__-KKHZ p {
        margin-left: 4em;
    }
}




@media (max-width: 490px) {
    .Footer_footer__QwD\\+f {
        margin-top: 8em;
        text-align: center;
        align-items: center;
        justify-content: center;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/footer/Footer.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,2BAA2B;IAC3B,gBAAgB;IAChB,mBAAmB;IACnB,iBAAiB;IACjB,kBAAkB;;IAElB,eAAe;IACf,gBAAgB;IAChB,cAAc;IACd,gBAAgB;;IAEhB,eAAe;IACf,yBAAiB;YAAjB,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,SAAS;AACb;;AAEA;IACI,eAAe;IACf,WAAW;IACX,YAAY;IACZ,cAAc;AAClB;;;AAGA;IACI;QACI,kBAAkB;QAClB,SAAS;QACT,OAAO;QACP,WAAW;QACX,UAAU;IACd;;IAEA;QACI,kBAAkB;IACtB;;IAEA;QACI,gBAAgB;IACpB;AACJ;;;;;AAKA;IACI;QACI,eAAe;QACf,kBAAkB;QAClB,mBAAmB;QACnB,uBAAuB;IAC3B;AACJ","sourcesContent":[".footer {\r\n    display: flex;\r\n    justify-content: flex-start;\r\n    min-height: 88px;\r\n    align-items: center;\r\n    padding-left: 4em;\r\n    padding-right: 4em;\r\n\r\n    font-size: 14px;\r\n    font-weight: 400;\r\n    color: #727588;\r\n    margin-top: auto;\r\n\r\n    flex-wrap: wrap;\r\n    user-select: none;\r\n}\r\n\r\n.footer div {\r\n    display: flex;\r\n    gap: .5em;\r\n}\r\n\r\n.footer div svg {\r\n    cursor: pointer;\r\n    width: 20px;\r\n    height: 20px;\r\n    color: #3A683D;\r\n}\r\n\r\n\r\n@media (min-width: 1300px) and (min-height: 820px) {\r\n    .fixed {\r\n        position: absolute;\r\n        bottom: 0;\r\n        left: 0;\r\n        width: 100%;\r\n        padding: 0;\r\n    }\r\n\r\n    .fixed div {\r\n        padding-right: 4em;\r\n    }\r\n\r\n    .fixed p {\r\n        margin-left: 4em;\r\n    }\r\n}\r\n\r\n\r\n\r\n\r\n@media (max-width: 490px) {\r\n    .footer {\r\n        margin-top: 8em;\r\n        text-align: center;\r\n        align-items: center;\r\n        justify-content: center;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": `Footer_footer__QwD+f`,
	"fixed": `Footer_fixed__-KKHZ`
};
export default ___CSS_LOADER_EXPORT___;
