// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DatePickerComponent_datePickerLabel__P2xXk {
    z-index: 100;
}

.DatePickerComponent_datePickerLabel__P2xXk input {
    padding-left: 10px;
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/datePicker/DatePickerComponent.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;AAChB;;AAEA;IACI,kBAAkB;IAClB,eAAe;AACnB","sourcesContent":[".datePickerLabel {\r\n    z-index: 100;\r\n}\r\n\r\n.datePickerLabel input {\r\n    padding-left: 10px;\r\n    cursor: pointer;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"datePickerLabel": `DatePickerComponent_datePickerLabel__P2xXk`
};
export default ___CSS_LOADER_EXPORT___;
