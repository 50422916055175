// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ModalReceivedPaymentSlip_container__twH\\+0 {
    flex-direction: column;
}

.ModalReceivedPaymentSlip_container__twH\\+0 .ModalReceivedPaymentSlip_actions__\\+HuQH {
    gap: .5em;
}

.ModalReceivedPaymentSlip_container__twH\\+0,
.ModalReceivedPaymentSlip_container__twH\\+0 .ModalReceivedPaymentSlip_actions__\\+HuQH {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    flex-wrap: wrap;
}

.ModalReceivedPaymentSlip_container__twH\\+0 .ModalReceivedPaymentSlip_actions__\\+HuQH input[type=button],
.ModalReceivedPaymentSlip_container__twH\\+0 .ModalReceivedPaymentSlip_actions__\\+HuQH button {
    margin-top: 1em;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 16px;
    height: 48px;
    width: 100px;
    padding: 14px, 20px, 14px, 20px;
    border-radius: 8px;
}

.ModalReceivedPaymentSlip_container__twH\\+0 .ModalReceivedPaymentSlip_actions__\\+HuQH .ModalReceivedPaymentSlip_btnNo__ucICN {
    background-color: #FFFFFF;
    border: 1px solid #E3E4E8;
    font-weight: 400;
}

.ModalReceivedPaymentSlip_container__twH\\+0 .ModalReceivedPaymentSlip_actions__\\+HuQH .ModalReceivedPaymentSlip_btnYes__SHyWA {
    background-color: #006930;
    color: #FFFFFF;
    font-weight: 750;

}`, "",{"version":3,"sources":["webpack://./src/features/paymentsSlip/modalReceivedPaymentSlip/ModalReceivedPaymentSlip.module.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;AAC1B;;AAEA;IACI,SAAS;AACb;;AAEA;;IAEI,aAAa;IACb,mBAAmB;IACnB,kBAAkB;IAClB,uBAAuB;IACvB,eAAe;AACnB;;AAEA;;IAEI,eAAe;IACf,eAAe;IACf,aAAa;IACb,uBAAuB;IACvB,mBAAmB;;IAEnB,aAAa;IACb,YAAY;IACZ,YAAY;IACZ,+BAA+B;IAC/B,kBAAkB;AACtB;;AAEA;IACI,yBAAyB;IACzB,yBAAyB;IACzB,gBAAgB;AACpB;;AAEA;IACI,yBAAyB;IACzB,cAAc;IACd,gBAAgB;;AAEpB","sourcesContent":[".container {\r\n    flex-direction: column;\r\n}\r\n\r\n.container .actions {\r\n    gap: .5em;\r\n}\r\n\r\n.container,\r\n.container .actions {\r\n    display: flex;\r\n    align-items: center;\r\n    text-align: center;\r\n    justify-content: center;\r\n    flex-wrap: wrap;\r\n}\r\n\r\n.container .actions input[type=button],\r\n.container .actions button {\r\n    margin-top: 1em;\r\n    cursor: pointer;\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n\r\n    padding: 16px;\r\n    height: 48px;\r\n    width: 100px;\r\n    padding: 14px, 20px, 14px, 20px;\r\n    border-radius: 8px;\r\n}\r\n\r\n.container .actions .btnNo {\r\n    background-color: #FFFFFF;\r\n    border: 1px solid #E3E4E8;\r\n    font-weight: 400;\r\n}\r\n\r\n.container .actions .btnYes {\r\n    background-color: #006930;\r\n    color: #FFFFFF;\r\n    font-weight: 750;\r\n\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `ModalReceivedPaymentSlip_container__twH+0`,
	"actions": `ModalReceivedPaymentSlip_actions__+HuQH`,
	"btnNo": `ModalReceivedPaymentSlip_btnNo__ucICN`,
	"btnYes": `ModalReceivedPaymentSlip_btnYes__SHyWA`
};
export default ___CSS_LOADER_EXPORT___;
