// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ViewPdf_container__3JviV {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}


.ViewPdf_buttons__hqNjP {
    display: flex;
    align-items: center;
    text-align: center;
    gap: 1em;
    float: left;
    margin-bottom: 1em;
    margin-top: 1em;
    flex-wrap: wrap;
}

.ViewPdf_buttons__hqNjP svg {
    width: 15px;
    height: 15px;
}

.ViewPdf_containerCanvas__bXJ\\+W {
    border: 1px solid black;
    width: 100%;
}


.ViewPdf_buttons__hqNjP button {
    text-align: center;
    margin-top: 1.6em;
    height: 42px;
    background-color: #006930;
    color: #FEFEFE;
    font-weight: 600;
    font-size: 14px;
    border-radius: 8px;
}

.ViewPdf_buttons__hqNjP button:hover {
    opacity: 1;
}

.ViewPdf_buttons__hqNjP button {
    padding: 1em;
}

.ViewPdf_buttons__hqNjP button:disabled {
    cursor: not-allowed;
    opacity: .5;
}

canvas {
    width: 100%;
    pointer-events: none;
}

@media (max-width: 600px) {

    .ViewPdf_buttons__hqNjP button {
        min-width: 100%;
    }
}

@media (max-width: 387px) {

    .ViewPdf_buttons__hqNjP button {
        font-size: 10px;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/viewPdf/ViewPdf.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,WAAW;AACf;;;AAGA;IACI,aAAa;IACb,mBAAmB;IACnB,kBAAkB;IAClB,QAAQ;IACR,WAAW;IACX,kBAAkB;IAClB,eAAe;IACf,eAAe;AACnB;;AAEA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,uBAAuB;IACvB,WAAW;AACf;;;AAGA;IACI,kBAAkB;IAClB,iBAAiB;IACjB,YAAY;IACZ,yBAAyB;IACzB,cAAc;IACd,gBAAgB;IAChB,eAAe;IACf,kBAAkB;AACtB;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,mBAAmB;IACnB,WAAW;AACf;;AAEA;IACI,WAAW;IACX,oBAAoB;AACxB;;AAEA;;IAEI;QACI,eAAe;IACnB;AACJ;;AAEA;;IAEI;QACI,eAAe;IACnB;AACJ","sourcesContent":[".container {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    width: 100%;\n}\n\n\n.buttons {\n    display: flex;\n    align-items: center;\n    text-align: center;\n    gap: 1em;\n    float: left;\n    margin-bottom: 1em;\n    margin-top: 1em;\n    flex-wrap: wrap;\n}\n\n.buttons svg {\n    width: 15px;\n    height: 15px;\n}\n\n.containerCanvas {\n    border: 1px solid black;\n    width: 100%;\n}\n\n\n.buttons button {\n    text-align: center;\n    margin-top: 1.6em;\n    height: 42px;\n    background-color: #006930;\n    color: #FEFEFE;\n    font-weight: 600;\n    font-size: 14px;\n    border-radius: 8px;\n}\n\n.buttons button:hover {\n    opacity: 1;\n}\n\n.buttons button {\n    padding: 1em;\n}\n\n.buttons button:disabled {\n    cursor: not-allowed;\n    opacity: .5;\n}\n\ncanvas {\n    width: 100%;\n    pointer-events: none;\n}\n\n@media (max-width: 600px) {\n\n    .buttons button {\n        min-width: 100%;\n    }\n}\n\n@media (max-width: 387px) {\n\n    .buttons button {\n        font-size: 10px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `ViewPdf_container__3JviV`,
	"buttons": `ViewPdf_buttons__hqNjP`,
	"containerCanvas": `ViewPdf_containerCanvas__bXJ+W`
};
export default ___CSS_LOADER_EXPORT___;
