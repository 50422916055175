// modal
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';

//css
import styles from './ModalReceivedPaymentSlip.module.css';

//interfaces
import { IContract } from '../../../interfaces/IContract';

// react router dom
import { useNavigate } from "react-router-dom";

//services
import { PaymentSlipService } from '../../../services/paymentSlip/PaymentSlipService';

//api
import { ApiException } from '../../../api/ApiException';

//utils
import { Toast } from '../../../utils/Toast';

//react
import { useState } from 'react';

//components
import Loading from '../../../components/loading/Loading';

type Props = {
    open: boolean
    onCloseModal(): void
    contract: IContract | undefined
}
const ModalReceivedPaymentSlip: React.FC<Props> = ({ open = false, onCloseModal, contract }) => {

    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false)
    const actionNo = async () => {
        toUpdateReceivedPaymentSlip(false, () => navigate(`/contract`, { state: { contract: contract, comingFromModalReceivedPaymentSlip: true } }));

    }

    const actionYes = async () => {
        toUpdateReceivedPaymentSlip(true, onCloseModal);
    }

    const toUpdateReceivedPaymentSlip = async (toUpdate: boolean, action: any) => {
        setLoading(true);
        PaymentSlipService.toUpdateReceivedPaymentSlip(
            {
                contractCode: contract?.contractCode || '',
                originCode: contract?.dataOrigin || '',
                receivedPaymentSlip: toUpdate
            }).then((response) => {
                if (response instanceof ApiException) {
                    Toast.error(response.message);
                    setLoading(false);
                } else {
                    setLoading(false);
                    action();
                }
            })
    }
    return (
        <>
            <Modal
                open={open}
                onClose={() => { }}
                center
                showCloseIcon={false}>
                <div className={styles.container}>
                    {loading && <Loading />}
                    <p>Recebeu carnê do contrato: <br /> <strong>{contract?.contractCode}</strong>?</p>
                    <div className={styles.actions}>
                        <button className={styles.btnNo} onClick={actionNo}>Não</button>
                        <button className={styles.btnYes} onClick={actionYes}>Sim</button>
                    </div>
                </div>

            </Modal>
        </>
    )
}

export default ModalReceivedPaymentSlip