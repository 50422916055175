import React from 'react'

//css
import styles from "./Login.module.css";

//components
import Input from '../../components/input/Input'

//icons
import { FaEye, FaEyeSlash } from 'react-icons/fa';

//react
import { useState } from 'react';

// reacptcha
import ReCAPTCHA from "react-google-recaptcha";
import { RecaptchaGoogleService } from '../../services/recaptcha_google/RecaptchaGoogleService';
import { IRecaptchaGoogleResponse } from '../../interfaces/IRecaptchaGoogleResponse';
import { ApiException } from '../../api/ApiException';

type Props = {
    handleSubmitPasswordForm(e: React.FormEvent, captcha: any): void
    cpfCnpj: string
    setCpfCnpj(cpfCnpj: string): void
    Formatters: any
    loading: boolean
    password: string
    setPassword(password: string): void
    confirmPassword: string
    setConfirmPassword(confirmPassword: string): void
    message: string
    Message: any,
    seePassword(id: string): string
    handleBackLogin(e: React.FormEvent): void,
    passwordLabel: string
    confirmPasswordLabel: string
    title: string
    attemps: number
    setMessage(message: string): void
    setLoading(loading: boolean): void
}
const SeePasswordFormScreen: React.FC<Props> = (
    {
        handleSubmitPasswordForm,
        cpfCnpj,
        setCpfCnpj,
        Formatters,
        loading,
        password,
        setPassword,
        confirmPassword,
        setConfirmPassword,
        message,
        Message,
        seePassword,
        handleBackLogin,
        passwordLabel = "Senha",
        confirmPasswordLabel = "Confirmar Senha",
        title = "Registro",
        attemps,
        setMessage,
        setLoading,
    }
) => {
    const [passwordId, setPasswordId] = useState("password");
    const [confirmPasswordId, setConfirmPasswordId] = useState("password");

    const [requiredPassword, setRequiredPassword] = useState<boolean>(false);
    const [requiredConfirmPassword, setRequiredConfirmPassword] = useState<boolean>(false);

    const [captchaValue, setCaptchaValue] = useState<any>();
    const key_site = process.env.REACT_APP_RECAPTCHA_KEY_SITE;
    const key_secret = process.env.REACT_APP_RECAPTCHA_KEY_SECRET;
    let captcha: any

    const handleInvalidInput = (event: any) => {
        event.preventDefault();
        if (!event.target.value)
            event.target.classList.add('required');
        if (event.target.name === 'password') setRequiredPassword(true);
        if (event.target.name === 'confirmPassword') setRequiredConfirmPassword(true);
    };

    const handleInputBlur = () => {
    };

    const setCaptchaRef = (ref: any) => {
        if (ref) {
            return captcha = ref;
        }
    };

    const onChange = (value: any) => {
        setCaptchaValue(value)
        setMessage('');
    }
    return (
        <form onSubmit={(e: React.FormEvent) => {
            e.preventDefault();
            if (!password || !cpfCnpj) {
                return;
            }
            setLoading(true);
            if (attemps > 1 && key_site && key_secret) {
                const dados = {
                    response: captchaValue,
                };
                RecaptchaGoogleService.sendTokenRecaptcha(dados).then((response) => {
                    if (response instanceof ApiException) {
                        captcha?.reset();
                        setCaptchaValue(null);
                        setLoading(false);
                    } else {
                        const recaptchaGoogleResponse: IRecaptchaGoogleResponse = response;
                        if (recaptchaGoogleResponse.success) {
                            handleSubmitPasswordForm(e, captcha);
                            setCaptchaValue(null);
                        }
                    }
                })
            } else {
                handleSubmitPasswordForm(e, null);
            }

        }}>
            <div className={styles.title}>
                <span>{title}</span>
            </div>
            <label>CPF/CNPJ<span className={styles.asterisk}>*</span></label>
            <Input
                disabled={true}
                placeholder='Digite seu CPF/CNPJ'
                value={cpfCnpj}
                onChange={(e) =>
                    setCpfCnpj(Formatters.formatterCpfCnpj(e.target.value))
                }
                required
            />
            <label>{passwordLabel} <span className={styles.asterisk}>*</span></label>
            <Input
                name='password'
                id="passwordId"
                disabled={loading}
                type="password"
                placeholder="Insira sua senha"
                value={password}
                maxLength={15}
                onChange={(e) =>

                    setPassword(e.target.value)
                }
                required
                onInvalid={handleInvalidInput}
                onBlur={handleInputBlur}
            />
            <div className={styles.show_password} onClick={() => setPasswordId(seePassword('passwordId'))}>
                {passwordId === "password" && <FaEye />}
                {passwordId !== "password" && <FaEyeSlash />}
            </div>
            {requiredPassword && !password && <span className='inputRequiredPassword'>Senha obrigatório</span>}

            <label>{confirmPasswordLabel}</label>
            <Input
                name='confirmPassword'
                id="confirmPasswordId"
                disabled={loading}
                type="password"
                placeholder="Confirme sua senha"
                value={confirmPassword}
                maxLength={15}
                onChange={(e) =>
                    setConfirmPassword(e.target.value)
                }
                required
                onInvalid={handleInvalidInput}
                onBlur={handleInputBlur}
            />
            <div className={styles.show_password} onClick={() => setConfirmPasswordId(seePassword('confirmPasswordId'))}>
                {confirmPasswordId === "password" && <FaEye />}
                {confirmPasswordId !== "password" && <FaEyeSlash />}
            </div>
            {requiredConfirmPassword && !confirmPassword && <span className='inputRequiredPassword'>Confirmação de senha obrigatório</span>}

            <div className={styles.info_password}>
                <span className={styles.regular}>A senha deve conter o mínimo de </span> <span className={styles.bold}>8 caracteres</span>
            </div>
            <div className={styles.info_password}>
                <span className={styles.regular}>Combine letras</span> <span className={styles.bold}>maiúsculas, mínusculas, caractere especial e números</span>
            </div>
            <div className={styles.captcha}>
                {attemps > 1 && key_site && key_secret && (
                    <ReCAPTCHA
                        ref={(r) => setCaptchaRef(r)}
                        className={styles.captcha}
                        sitekey={key_site}
                        onChange={onChange}
                    />
                )}
            </div>
            {message && <Message message={message} type="error_login" />}
            <div className={styles.box_button}>
                <div className={styles.cancel_button}>
                    {<button type='button' onClick={handleBackLogin}>Cancelar</button>}
                </div>
                <div className={styles.continue_button}>
                    {attemps > 1 && key_site && key_secret ? (
                        <>
                            {loading && <button disabled>Aguarde...</button>}
                            {captchaValue && !loading && <button>Continuar</button>}
                            {!captchaValue && !loading && <button disabled>Preencha o captcha</button>}
                        </>
                    ) : (
                        <>
                            {loading && <button disabled>Aguarde...</button>}
                            {!loading && <button>Continuar</button>}
                        </>
                    )}
                </div>
            </div >

        </form >
    )
}

export default SeePasswordFormScreen